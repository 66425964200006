<template>
  <div id="ConstructionSites">
    <h1 class="brand-color-dark headline">Baustellen</h1>
    
    <NoDataBox v-if="data.length <= 0 && !isLoading" headline="Keine Informationen vorhanden">Schaue später wieder vorbei.</NoDataBox>

    <p v-show="isLoading">
      <font-awesome-icon icon="spinner" class="icon" pulse /> Daten werden geladen...
    </p>

    <div class="content-card-container grid grid-cols-1 gap-3" v-if="data">
      <FileItem :data="d" v-for="d in data.data" :key="d.id"></FileItem>
    </div>

    <div class="mapExchangeCard"></div>
  </div>
</template>


<script>
import NoDataBox from "@/components/NoDataBox";
import FileItem from "@/components/FileItem";

import { mapGetters } from "vuex";

export default {
  name: "ConstructionSites",
  components: {
    NoDataBox,
    FileItem
  },
  data() {
    return {
      data: false,
      isLoading: true
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    this.$store.dispatch("fetchBoardConstructionsites").then((a) => {
      if (a.status == true) {
        this.data = a.result
      }

      this.isLoading = false
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>